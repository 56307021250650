<template>
  <v-app>
    <v-app-bar app clipped-left color="primary">
      <v-toolbar-title style="color: white">图片分享</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon color="white" @click="switchTheme">
        <v-icon>{{ themeIcon }}</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  data: () => ({
    themeIcon: "mdi-moon-waxing-crescent",
  }),
  methods: {
    switchTheme: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (this.$vuetify.theme.dark) {
        this.themeIcon = "mdi-weather-sunny";
        window.localStorage.setItem("theme", "dark");
        return;
      }
      this.themeIcon = "mdi-moon-waxing-crescent";
      window.localStorage.setItem("theme", "sunny");
    },
  },
  mounted() {
    const theme = window.localStorage.getItem("theme");
    if (theme === 'dark') {
      this.$vuetify.theme.dark = false;
      this.switchTheme();
    }
  },
}
</script>
<style>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>