import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from "axios";
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false
Vue.use(VueClipboard)

axios.defaults.baseURL = "https://lilinnan.com/image-share-api/api/v1/"
// axios.defaults.baseURL = "http://localhost:8081/api/v1/"
Vue.prototype.$http = axios

axios.interceptors.response.use(response => {
    return response.data;
}, error => {
    return error.response.data;
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
