<template>
  <v-container>
    <v-row>
      <v-col md="6" offset-md="3">
        <v-form
            ref="form">
          <v-textarea solo label="上传图片即显示直链" v-model="links" readonly></v-textarea>
          <v-btn class="w-100" @click="copy">复制所有</v-btn>
          <v-btn class="w-100 mt-3" @click="createAlbum">生成网页展示</v-btn>
          <image-upload v-model="photos" class="mt-5"/>
        </v-form>
        <v-col class="mt-6" md="6" offset-md="3" style="text-align: center" v-if="photos.length===1">
          <vue-qr :text="photos[0]"/>
          <br>
          <v-chip outlined>
            扫码查看这张图片
          </v-chip>
        </v-col>
      </v-col>
    </v-row>
    <v-dialog v-model="albumDialog" persistent max-width="360">
      <v-card>
        <v-container>
          <v-row>
            <v-card-title class="headline">成功</v-card-title>
            <v-spacer/>
            <v-btn icon @click="albumDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-card-text>相册链接：{{ albumLink }}</v-card-text>
          <v-card-text style="text-align: center">
            <vue-qr :text="albumLink"/>
            <br>
            <v-chip outlined>
              扫码查看这个相册
            </v-chip>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import ImageUpload from "@/components/ImageUpload";
import VueQr from 'vue-qr'

export default {
  name: "Home",
  components: {ImageUpload, VueQr},
  data: () => ({
    photos: [],
    albumLink: '',
    albumDialog: false
  }),
  methods: {
    createAlbum() {
      if (this.photos.length <= 0) {
        this.$toast.warning("请先上传图片");
        return;
      }
      this.$http.post("album", this.photos).then(value => {
        if (value.status !== 200) {
          this.$toast.error("相册创建失败！");
          return;
        }
        this.$toast.success("相册创建成功");
        this.albumLink = "https://img.lilinnan.com/#/album/" + value.data;
        this.albumDialog = true;
      })
    },
    copy() {
      if (!this.links) {
        this.$toast.warning("请先上传图片");
        return;
      }
      this.$copyText(this.links).then(() => {
        this.$toast.success("复制成功");
      }, () => {
        this.$toast.error("复制失败");
      })
    },
  },
  computed: {
    links: function () {
      let showLinks = "";
      if (this.photos.length === 0) {
        return showLinks;
      }
      for (let i = 0; i < this.photos.length - 1; i++) {
        showLinks += this.photos[i] + "\n";
      }
      showLinks += this.photos[this.photos.length - 1];
      return showLinks;
    }
  },
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>