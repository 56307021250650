<template>
  <v-container>
    <v-dialog v-model="unknown" persistent max-width="360">
      <v-card>
        <v-card-title>不存在的相册</v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="tip" persistent max-width="360">
      <v-card>
        <v-container>
          <v-row>
            <v-spacer/>
            <v-btn icon @click="tip=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-card-title>如需要保存图片，请在点击图片后，新打开的页面中进行长按或是右键保存</v-card-title>
        </v-container>

      </v-card>
    </v-dialog>
    <v-dialog v-model="showImage" fullscreen hide-overlay>
      <v-card>
        <v-toolbar dark color="primary">
          <v-spacer></v-spacer>
          <v-btn icon dark @click="showImage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col class="offset-md-4" md="4">
              <img :src="oneUrl" class="mt-5" width="100%">
            </v-col>
          </v-row>
          <v-col class="offset-md-4" md="4" style="text-align: center">
            <vue-qr :text="oneUrl"/>
            <br>
            <v-chip outlined>
              扫码查看这张图片
            </v-chip>
          </v-col>
        </v-container>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="offset-md-3" md="6">
        <v-img
            @click="openInNewWindow(url)"
            contain
            v-for="url of urls"
            :src="url"
            class="grey lighten-2 mt-3"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-textarea solo label="本页全部图片链接" class="mt-3 w-100" v-model="links" readonly></v-textarea>
        <v-btn class="w-100" @click="copy">复制所有</v-btn>
        <v-btn class="w-100 mt-3" @click="createNewAlbum">我也要创建相册</v-btn>
        <v-col class="offset-md-4" md="4" style="text-align: center">
          <vue-qr :text="location"/>
          <br>
          <v-chip outlined>
            扫码查看这个相册
          </v-chip>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueQr from 'vue-qr'

export default {
  name: "Album",
  components: {VueQr},
  data: () => ({
    uuid: '',
    urls: [],
    unknown: false,
    showImage: false,
    oneUrl: '',
    tip: false
  }),
  methods: {
    searchAlbum() {
      this.$http.get("album/" + this.uuid).then(value => {
        if (value.status === 400) {
          this.$toast.error("不存在的相册");
          this.unknown = true;
          return;
        }
        if (value.status === 200) {
          this.urls = value.data;
        }
      })
    },
    openInNewWindow(url) {
      this.showImage = true;
      this.oneUrl = url;
    },
    copy() {
      if (!this.links) {
        this.$toast.warning("请先上传图片");
        return;
      }
      this.$copyText(this.links).then(() => {
        this.$toast.success("复制成功");
      }, () => {
        this.$toast.error("复制失败");
      })
    },
    copyOne() {
      this.$copyText(this.oneUrl).then(() => {
        this.$toast.success("复制成功");
      }, () => {
        this.$toast.error("复制失败");
      })
    },
    createNewAlbum() {
      window.location.href = "https://img.lilinnan.com/";
    },
  },
  mounted() {
    this.uuid = this.$route.params.uuid;
    this.searchAlbum();
  },
  computed: {
    links: function () {
      let showLinks = "";
      if (this.urls.length === 0) {
        return showLinks;
      }
      for (let i = 0; i < this.urls.length - 1; i++) {
        showLinks += this.urls[i] + "\n";
      }
      showLinks += this.urls[this.urls.length - 1];
      return showLinks;
    },
    location: function () {
      return window.location.href;
    }
  },
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>